import { keyframes } from '@mantine/styles';

const colors = {
  blue: {
    main: '#0080FF',
  },
  green: {
    main: '#32cd32',
  },
  red: {
    main: '#F21313',
  },
  yellow: {
    main: '#FFD555',
  },
} as const;

export function Lightbulb({ variant, ...props }: Props) {
  return variant === 'star' ? <StarVariant {...props} /> : <DefaultVariant {...props} />;
}

function DefaultVariant({ color, size, brightness = 1, animated, className, ...props }: Props) {
  const { main } = colors[color as LightbulbColor];
  const flickerClass = `demo-${brightness * 100}${animated ? '-animated' : ''}`;

  return (
    <svg
      {...props}
      className={`${className} ${flickerClass}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size ?? props.width}
      height={size ?? props.height}
    >
      <g transform="matrix(.12692 0 0 .12692 -43.144 -42.232)">
        <path d="M430.8 511.9h.4c15.1-.3 40.9-25.8 48.3-78.5 4.9-35-5-51.2-26.9-56 2.1 0 3.8-1.7 3.8-3.9v-10.3c0-2.1-1.7-3.9-3.8-3.9h-1c.1-.4.2-.8.2-1.2v-6.3c0-2.1-1.7-3.9-3.9-3.9h-33.8c-2.1 0-3.9 1.7-3.9 3.9v6.3c0 .4.1.8.2 1.2h-1c-2.1 0-3.9 1.7-3.9 3.9v10.3c0 2.1 1.7 3.9 3.9 3.9-21.9 4.9-31.7 21-26.9 56 7.4 52.8 33.1 78.2 48.3 78.5z" />
        <path
          d="M452.5 377.4h-43c-21.9 4.9-31.7 21-26.9 56 7.3 52.8 33.1 78.2 48.3 78.5h.4c15.1-.3 40.9-25.8 48.3-78.5 4.6-35-5.2-51.1-27.1-56z"
          className="st0"
          style={{ fill: '#000' }}
        />
        <path
          d="M452.5 377.4h-43c-21.9 4.9-31.7 21-26.9 56 7.3 52.8 33.1 78.2 48.3 78.5h.4c15.1-.3 40.9-25.8 48.3-78.5 4.6-35-5.2-51.1-27.1-56z"
          style={{
            fill: main,
            stroke: main,
          }}
        />
        <path
          d="M415.2 413.8c-4.3 9.8-12.2 15.8-17.7 13.4-5.5-2.4-6.5-12.3-2.2-22.2 4.3-9.8 12.2-15.8 17.7-13.4 5.5 2.4 6.5 12.3 2.2 22.2z"
          className="st1"
          style={{ fill: '#fff' }}
        />
        <path
          d="M452.5 359.3h-1c.1-.4.2-.8.2-1.2v-6.3c0-2.1-1.7-3.9-3.9-3.9H414c-2.1 0-3.9 1.7-3.9 3.9v6.3c0 .4.1.8.2 1.2h-1c-2.1 0-3.9 1.7-3.9 3.9v10.3c0 2.1 1.7 3.9 3.9 3.9h43.1c2.1 0 3.8-1.7 3.8-3.9v-10.3c.2-2.1-1.6-3.9-3.7-3.9z"
          className="st2"
          style={{ fill: '#548a3e' }}
        />
        <linearGradient
          id="bulb-gradient"
          x1={396.059}
          x2={473.519}
          y1={452.791}
          y2={452.791}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.014} style={{ stopColor: 'rgba(0,0,0,.1)' }} />
          <stop offset={1} style={{ stopColor: 'rgba(0,0,0,0.3)' }} />
        </linearGradient>
        <path
          d="M472.7 406.5c-.2-1.6-.5-3.1-.7-4.7-.5-.1-.9-.2-1.4-.2.1 1.4 0 2.9-.5 4.3-3.5 9.5-4.7 19.7-8.2 29.3-4.2 11.3-9.6 23.1-18.5 31.6-10.1 9.7-24.5 12.9-36.7 5-4.1-2.7-7.7-6.1-10.5-10 2.7 11.5 7.8 22.2 15.6 31.4 4.4 5.3 10.2 10.3 17.4 10.7 2.5.1 5-.3 7.3-1.2 1.3.1 2.8-.3 4-1.3 16.5-13.3 27.1-32.6 29.9-53.4 1.1-4.3 1.9-8.7 2.5-13.2.3-2.6.6-5.2.8-7.8-.5-6.9-.8-13.7-1-20.5z"
          className="st3"
          style={{ fill: `url(#bulb-gradient)` }}
        />
      </g>
    </svg>
  );
}

export const heartbeat = keyframes({
  '0%': {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
    fill: 'red',
  },
  '100%': {
    opacity: 0,
    fill: 'blue',
  },
});

function StarVariant({
  color,
  size,
  brightness = 1,
  animated,
  className,
  ...props
}: Omit<Props, 'variant'>) {
  const { main } = colors[color as LightbulbColor];
  const flickerClass = `demo-${brightness * 100}${animated ? '-animated' : ''}`;

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size ?? props.width}
      height={size ?? props.height}
      className={`${className} ${flickerClass}`}
    >
      <g>
        <path d="M7.34 14.838s-1.186 4.808-.37 5.475c.815.667 4.882-2.002 4.882-2.002s3.992 2.595 4.882 2.002c.89-.593-.37-5.105-.37-5.105s3.97-2.647 3.483-4.003c-.466-1.281-5.253-1.26-5.253-1.26s-.285-2.7-1.387-3.992c.106-.032.19-.17.19-.35v-.974c0-.201-.105-.36-.232-.36H13.1c.01-.032.01-.074.01-.106V3.57c0-.202-.105-.36-.232-.36h-2.065c-.127 0-.233.158-.233.36v.593c0 .042.01.074.01.106h-.063c-.127 0-.233.159-.233.36v.974c0 .201.106.36.233.36h.074c-1.09 1.26-1.42 3.834-1.42 3.834s-4.85.127-5.326 1.26c-.54 1.303 3.484 3.78 3.484 3.78z" />
        <path
          d="M507.4 423.4c-4.4-12.1-49.6-11.9-49.6-11.9s-2.7-25.5-13.1-37.7c-.1 0-.3.1-.4.1h-24.2c-10.3 11.9-13.4 36.2-13.4 36.2s-45.8 1.2-50.3 11.9c-5.2 12.2 32.9 35.7 32.9 35.7s-11.2 45.4-3.5 51.7c7.7 6.3 46.1-18.9 46.1-18.9s37.7 24.5 46.1 18.9c8.4-5.6-3.5-48.2-3.5-48.2s37.5-25.1 32.9-37.8z"
          style={{
            fill: main,
            stroke: main,
          }}
          transform="matrix(.1059 0 0 .1059 -33.877 -33.623)"
        />
        <path
          d="M434.2 394.7c-2.4 6.9-7.8 11.3-12.1 9.8s-5.8-8.3-3.3-15.2c2.4-6.9 7.9-11.3 12.1-9.8 4.2 1.5 5.7 8.3 3.3 15.2z"
          className="st1"
          style={{ fill: '#fff' }}
          transform="matrix(.1059 0 0 .1059 -33.877 -33.623)"
        />
        <path
          d="M444.3 357.8h-.6c.1-.3.1-.7.1-1v-5.6c0-1.9-1-3.4-2.2-3.4h-19.5c-1.2 0-2.2 1.5-2.2 3.4v5.6c0 .4.1.7.1 1h-.6c-1.2 0-2.2 1.5-2.2 3.4v9.2c0 1.9 1 3.4 2.2 3.4h24.9c1.2 0 2.2-1.5 2.2-3.4v-9.2c0-1.8-1-3.4-2.2-3.4z"
          className="st2"
          style={{ fill: '#548a3e' }}
          transform="matrix(.1059 0 0 .1059 -33.877 -33.623)"
        />
      </g>
      <linearGradient
        id="star-gradient"
        x1={385.484}
        x2={505.678}
        y1={466.034}
        y2={466.034}
        gradientTransform="matrix(.1059 0 0 .1059 -33.877 -33.623)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} style={{ stopColor: 'rgba(0,0,0,.1)' }} />
        <stop offset={1} style={{ stopColor: 'rgba(0,0,0,.3)' }} />
      </linearGradient>
      <path
        d="M19.657 11.29a.056.056 0 0 0-.053-.031c-.042-.011-.095-.011-.127.031 0 0 0 .011-.01.011l-.021.021a7.205 7.205 0 0 1-3.951 2.553c-.158.031-.402.063-.423.264a.172.172 0 0 0 .053.159c0 .021 0 .042.01.074.551.975.763 2.118.604 3.22-.042.254-.085.519-.18.773-.053.159-.148.19-.307.148a2.426 2.426 0 0 1-.392-.127 5.99 5.99 0 0 1-1.419-.784c-.519-.37-.985-.783-1.462-1.196-.042-.032-.106-.043-.148 0-1.017 1.165-2.118 2.319-3.516 3.028-.212.106-.445.212-.688.191-.201-.021-.381-.148-.424-.349-.021-.096-.148-.096-.19-.022a.424.424 0 0 0-.053.318c-.022.201 0 .445.18.561.222.138.582.064.826.011.381-.085.752-.254 1.101-.445.307-.137.614-.286.911-.455.318-.18.614-.392.9-.604.011-.01.021-.01.032-.021.339-.222.667-.466.985-.72.593.423 1.196.847 1.821 1.218.561.339 1.165.635 1.801.794.148.064.307.117.466.138.328.084.667.084.826-.286.105-.254.074-.551.053-.816a14.597 14.597 0 0 0-.096-.974 11.078 11.078 0 0 0-.222-1.176 7.681 7.681 0 0 0-.212-.974c-.021-.127-.053-.243-.085-.371a2.81 2.81 0 0 0-.084-.307c-.011-.053-.032-.095-.043-.148v-.01l.011-.011.063-.032h.011c.032 0 .053-.021.063-.032a9.77 9.77 0 0 0 .795-.508 6.114 6.114 0 0 0 1.546-1.26c.021-.032.042-.053.063-.085.308-.318.583-.678.784-1.07.095-.18.17-.37.201-.571l.011-.011c.021-.053.011-.085-.011-.117Z"
        className="st3"
        style={{ fill: `url(#star-gradient)` }}
      />
    </svg>
  );
}

type LightbulbColor = 'blue' | 'green' | 'red' | 'yellow';
type Props = React.ComponentPropsWithoutRef<'svg'> & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  color: LightbulbColor | (string & {});
  brightness?: number;
  size?: number;
  variant?: 'default' | 'star';
  className?: string;
  animated?: boolean;
};
